import styles from './ButtonRound.module.scss'
import React from 'react'
import ArrowLineIcon from '@/assets/svg/arrow-line.svg'
import classNames from 'classnames'

type Props = {
  onClick?: () => void,
  arrowDirection?: 'left' | 'right',
}

const ButtonRound = (props: Props) => {

  const classes = classNames({
    [styles.component]: true,
    [styles.left]: props.arrowDirection === 'left',
    [styles.right]: props.arrowDirection === 'right',
  })

  return (
    <button className={classes} onClick={props.onClick}>
      <ArrowLineIcon />
    </button>
  )
}

ButtonRound.defaultProps = {
  arrowDirection: 'right',
}

export default ButtonRound