import React from 'react'
import styles from './FreeAssembly.module.scss'
import image from '@/assets/images/free-assembly.png'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useGetCustomerQuery } from '@/api/customer'

type Props = {}

const FreeAssembly = (props: Props) => {
  const {data: customer} = useGetCustomerQuery()
  const { t } = useTranslation('intro')

  if(customer?.isReseller)
    return null

  return (
    <div className={styles.component}>
      <Image src={image} alt="Free assembly" />
      <section>
        <h2>{t('freeAssemblyTitle')}</h2>
        <span>{t('freeAssemblyText')}</span>
      </section>
    </div>
  )
}

export default FreeAssembly