import styles from './Hero.module.scss'
import Image from 'next/image'
import React from 'react'
import heroImage from '@/assets/images/hero.png'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Raw from '../Raw/Raw'

type Props = {}

const Hero = (props: Props) => {
  const { t } = useTranslation('common')

  return (
    <div className={classNames(styles.container)}>
      <Image
        src={heroImage}
        alt="Hero"
        height={502}
        width={1200}
      />
      <div className={styles.heroText}>
        <Raw content={t('heroText')} tagName="h1" />
      </div>
    </div>
  )
}

export default Hero