import { useGetCurrenciesQuery } from '@/api/currencies'
import { useGetCustomerQuery } from '@/api/customer'
import { selectCurrency, selectIncludeVat } from '@/features/app/appSlice'
import { formatPrice } from '@/utils/helpers'
import { useAppSelector } from '@/utils/hooks/useAppSelector'
import { useMemo } from 'react'

/**
 * Custom hook to get price with currency symbol and calculated VAT
 * @returns 
 */
const usePrice = () => {
  const { data } = useGetCurrenciesQuery()
  const selectedCurrency = useAppSelector(selectCurrency)
  const includeVat = useAppSelector(selectIncludeVat)
  const { data: customer } = useGetCustomerQuery()

  const currencyRate = useMemo(() => {
    if (!data)
      return null

    const currencyRate = data
      ?.find((currency) => currency.currencyCode === selectedCurrency)
      ?.currencyRate

    return currencyRate
  }, [data, selectedCurrency])

  /**
   * Get price with currency symbol and calculated VAT
   * @param price 
   * @param showSymbol 
   * @returns 
   */
  const getPrice = (price?: number, showSymbol?: boolean) => {
    if (!price)
      return '-'

    if (!currencyRate)
      return '-'

    const vatPercent = customer?.vatPercentage ?? 25
    let calculated = price

    // Remove VAT
    if (!includeVat)
      calculated = calculated / (vatPercent / 100 + 1)

    calculated = (calculated / currencyRate)

    return addSymbol(calculated, showSymbol, selectedCurrency)
  }

  /**
   * Get formatted price based on selected currency excluding VAT calculation
   * @param price 
   * @param showSymbol 
   * @returns 
   */
  const getFormattedPrice = (price?: number, showSymbol?: boolean) => {
    if (!price)
      return '-'

    if (!currencyRate)
      return '-'

    let calculated = (price / currencyRate)

    return addSymbol(calculated, showSymbol, selectedCurrency)
  }

  const addSymbol = (calculated: number, showSymbol: boolean | undefined, selectedCurrency: string) => {
    let returnPrice = ""

    if (showSymbol)
      returnPrice = formatPrice(calculated) + " " + selectedCurrency
    else
      returnPrice = formatPrice(calculated)

    return returnPrice
  }

  return {
    price: getPrice,
    formatPrice: getFormattedPrice
  }
}

export default usePrice
