// hook to detect swipe on mobile devices, call a callback function when swipe is detected and what direction it was

import { useEffect } from 'react'

export enum SwipeDirection {
  Left = 'left',
  Right = 'right',
  Up = 'up',
  Down = 'down'
}

const useSwipe = (ref: any, callback: (direction: SwipeDirection) => void) => {
  useEffect(() => {
    let xDown: number | null = null
    let yDown: number | null = null

    const handleTouchStart = (evt: any) => {
      xDown = evt.touches[0].clientX
      yDown = evt.touches[0].clientY
    }

    const handleTouchMove = (evt: any) => {
      if (!xDown || !yDown) {
        return
      }

      let xUp = evt.touches[0].clientX
      let yUp = evt.touches[0].clientY

      let xDiff = xDown - xUp
      let yDiff = yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          callback(SwipeDirection.Left)
        } else {
          callback(SwipeDirection.Right)
        }
      } else {
        if (yDiff > 0) {
          callback(SwipeDirection.Up)
        } else {
          callback(SwipeDirection.Down)
        }
      }

      xDown = null
      yDown = null
    }

    ref.current.addEventListener('touchstart', handleTouchStart, false)
    ref.current.addEventListener('touchmove', handleTouchMove, false)

    return () => {
      ref.current?.removeEventListener('touchstart', handleTouchStart)
      ref.current?.removeEventListener('touchmove', handleTouchMove)
    }
  }, [ref, callback])
}

export default useSwipe