import styles from './LicensePlateInput.module.scss'
import React, { useEffect, useRef } from 'react'
import Image from 'next/image'

type Props = {
  value: string
  countryCode: string
  onValueChange: (value: string, isValid: boolean) => void
  animated?: boolean
}

const LicensePlateInput = (props: Props) => {
  const [placeholder, setPlaceholder] = React.useState<string>('')

  const countries = [
    {
      countryCode: 'AU',
      maxLength: 7,
      regexPattern: /^[A-Z0-9]{1,7}$/,
      image: '/countries/au.png',
    },
    {
      countryCode: 'BR',
      maxLength: 7,
      regexPattern: /^[A-Z]{3}-[0-9]{4}$/,
      image: '/countries/br.png',
    },
    {
      countryCode: 'CH',
      maxLength: 7,
      regexPattern: /^[A-Z]{2}[0-9]{6}$/,
      image: '/countries/ch.png',
    },
    {
      countryCode: 'DE',
      maxLength: 8,
      regexPattern: /^[A-Z]{1,3}[A-Z]{1,2}[0-9]{1,4}$/,
      image: '/countries/de.png',
    },
    {
      countryCode: 'DK',
      maxLength: 8,
      regexPattern: /^[A-Z0-9]{6,8}$/,
      image: '/countries/dk.png',
    },
    {
      countryCode: 'ES',
      maxLength: 7,
      regexPattern: /^[0-9]{4}[A-Z]{3}$/,
      image: '/countries/es.png',
    },
    {
      countryCode: 'FI',
      maxLength: 8,
      regexPattern: /^[A-Z0-9]{6,8}$/,
      image: '/countries/fi.png',
    },
    {
      countryCode: 'FR',
      maxLength: 9,
      regexPattern: /^[A-Z]{2}-[0-9]{3}-[A-Z]{2}$/,
      image: '/countries/fr.png',
    },
    {
      countryCode: 'GB',
      maxLength: 7,
      regexPattern: /^[A-Z]{2}[0-9]{2}[A-Z]{3}$/,
      image: '/countries/gb.png',
    },
    {
      countryCode: 'IE',
      maxLength: 12,
      regexPattern: /^[0-9]{2}-[A-Z]{1,2}-[0-9]{1,6}$/,
      image: '/countries/ie.png',
    },
    {
      countryCode: 'IT',
      maxLength: 7,
      regexPattern: /^[A-Z]{2}[0-9]{3}[A-Z]{2}$/,
      image: '/countries/it.png',
    },
    {
      countryCode: 'NL',
      maxLength: 8,
      regexPattern: /^[A-Z0-9]{1,3}-[A-Z0-9]{1,3}-[A-Z0-9]{1,3}$/,
      image: '/countries/nl.png',
    },
    {
      countryCode: 'NO',
      maxLength: 7,
      regexPattern: /^[A-Z0-9]{6,7}$/,
      image: '/countries/no.png',
    },
    {
      countryCode: 'NZ',
      maxLength: 6,
      regexPattern: /^[A-Z0-9]{1,6}$/,
      image: '/countries/nz.png',
    },
    {
      countryCode: 'PT',
      maxLength: 8,
      regexPattern: /^[0-9]{2}-[A-Z]{2}-[0-9]{2}$/,
      image: '/countries/pt.png',
    },
    {
      countryCode: 'SE',
      maxLength: 7,
      regexPattern: /^[A-Z0-9]{6,7}$/,
      image: '/countries/se.png',
    },
    {
      countryCode: 'US',
      maxLength: 7,
      regexPattern: /^[A-Z0-9]{1,7}$/,
      image: '/countries/us.png',
    }
  ]  

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.toUpperCase().replace(/\s/g, '')
    let isValid = false

    const maxPlateLength = countries.find(c => c.countryCode === props.countryCode)?.maxLength
    const regexPattern = countries.find(c => c.countryCode === props.countryCode)?.regexPattern

    if (maxPlateLength === undefined || regexPattern === undefined)
      return

    // Truncate the input value to the maximum allowed length
    inputValue = inputValue.substring(0, maxPlateLength)

    // Replace the value with only the valid characters based on the regex pattern
    inputValue = inputValue.replace(new RegExp(`[^${regexPattern.source}]`, 'g'), '')

    if (regexPattern.test(inputValue))
      isValid = true

    props.onValueChange(inputValue, isValid)
  }

  useEffect(() => {
    const placeholder = 'ABC123'

    if (!props.animated) {
      setPlaceholder(placeholder)
      return
    }

    let i = 0
    const interval = setInterval(() => {
      setPlaceholder(placeholder.substring(0, i))
      i++
      if (i > placeholder.length) {
        clearInterval(interval)
      }
    }, 200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selected = countries.find(c => c.countryCode === props.countryCode) || countries[0]

  return (
    <div className={styles.component}>
      <div className={styles.block}>
        <div className={styles.countryDropdown}>
          <div className={styles.selected}>
            <div className={styles.flag}>
              {selected && (
                <Image src={selected?.image} alt={selected?.countryCode} width="20" height="15" />
              )}
            </div>
          </div>
        </div>
      </div>
      <input
        value={props.value}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
      />
    </div>
  )
}

export default LicensePlateInput