import styles from './CategorySelector.module.scss'
import { useTranslation } from 'next-i18next'
import React from 'react'
import wheel from '@/assets/images/wheel.png'
import rim from '@/assets/images/rim.png'
import tire from '@/assets/images/tire.png'
import accessory from '@/assets/images/accessory.png'
import Image, { StaticImageData } from 'next/image'
import classNames from 'classnames'
import Link from 'next/link'
import useLocalizedPath from '@/utils/hooks/useLocalizedPath'

type Category = {
  name: string,
  text: string,
  image: StaticImageData
}

const Category = (props: Category) => {
  return (
    <div className={classNames(styles.category)}>
      <Image
        src={props.image}
        alt={props.text}
        width={299}
        height={299}
      />
      <span>{props.text}</span>
    </div>
  )
}

const CategorySelector = () => {
  const { t } = useTranslation('common')
  const getLocalizedPath = useLocalizedPath()

  return (
    <div className={styles.container}>
      <Link href={getLocalizedPath('wheels')}>
        <Category
          name="wheels"
          text={t('search.wheel')}
          image={wheel}
        />
      </Link>
      <Link href={getLocalizedPath('rims')}>
        <Category
          name="rims"
          text={t('search.rim')}
          image={rim}
        />
      </Link>
      <Link href={getLocalizedPath('tyres')}>
        <Category
          name="tyres"
          text={t('search.tire')}
          image={tire}
        />
      </Link>
      <Link href={getLocalizedPath('accessories')}>
        <Category
          name="accessories"
          text={t('search.accessory')}
          image={accessory}
        />
      </Link>
    </div>
  )
}

export default CategorySelector