
import styles from './Instagram.module.scss'
import { useGetFeedQuery } from '@/api/instagramFeed'
import React, { useCallback, useEffect, useMemo } from 'react'
import Image from 'next/image'
import ButtonRound from '@/components/buttons/ButtonRound/ButtonRound'
import classNames from 'classnames'
import useSwipe, { SwipeDirection } from '@/utils/hooks/useSwipe'
import { useTranslation } from 'next-i18next'

type Props = {}

type InstagramPostProps = {
  caption: string,
  mediaUrl: string,
  permalink: string
}

const InstagramPost = (props: InstagramPostProps) => {
  // workaround to replace cdn url to the one allowed in the config
  const mediaUrl = props.mediaUrl.replace(/^[^.]*/, 'https://scontent-lhr8-1')

  return (
    <div
      className={styles.post}
      onClick={() => window.open(props.permalink, '_blank')}>
      <Image
        src={mediaUrl}
        alt={props.caption}
        width={333}
        height={333}
        loading="lazy"
        className={styles.image}
      />
      <div className={styles.caption}>
        {props.caption}
      </div>
    </div>
  )
}

const Instagram = (props: Props) => {
  const { data } = useGetFeedQuery()
  const ref = React.useRef<HTMLDivElement>(null)
  const [currentGroup, setCurrentGroup] = React.useState(0)
  const [groups, setGroups] = React.useState<any>([])
  const { t } = useTranslation('common')

  const onClick = useCallback((direction: 'left' | 'right') => {
    if (direction === 'left') {
      // if previous item falls below 0, set to total items, otherwise set to previous item
      if (currentGroup === 0)
        setCurrentGroup(groups.length - 1)
      else
        setCurrentGroup(currentGroup - 1)
    } else if (direction === 'right') {
      // if next item falls outside of total items, set to 0, otherwise set to next item
      if (currentGroup === groups.length - 1)
        setCurrentGroup(0)
      else
        setCurrentGroup(currentGroup + 1)
    }
  }, [currentGroup, groups.length])

  const onSwipe = (direction: SwipeDirection) => {
    if (direction === SwipeDirection.Left) {
      onClick('right')
    } else if (direction === SwipeDirection.Right) {
      onClick('left')
    }
  }

  useSwipe(ref, onSwipe)

  const handleResize = useCallback(() => {
    if (!data?.length) return

    const totalWidth = ref.current?.clientWidth
    const availableWidth = (totalWidth || 0) - 200

    let visiblePosts = 1
    if (availableWidth > 900)
      visiblePosts = 3
    else if (availableWidth > 600)
      visiblePosts = 2

    const groupCount = data.length / visiblePosts

    // create groups of data based on groupCount
    const groups = []
    for (let i = 1; i <= groupCount; i++) {
      const take = i * visiblePosts
      const skip = take - visiblePosts
      groups.push(data.slice(skip, take))
    }

    setGroups(groups)
    setCurrentGroup(0)
  }, [data])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data?.length) {
      handleResize()
    }
  }, [data, handleResize])

  const totalWidth = ref.current?.clientWidth

  const customStyle = {
    transform: `translateX(-${currentGroup * (totalWidth || 0)}px)`,
  }

  const groupStyle = {
    minWidth: `${totalWidth}px`,
  }

  return (
    <div className={styles.component} ref={ref}>
      <h2>{t('intro:latesFromSocialMedia')}</h2>
      <div className={classNames(styles.button, styles.left)}>
        <ButtonRound
          onClick={() => onClick('left')}
          arrowDirection="left"
        />
      </div>
      <div className={styles.posts} style={customStyle}>
        {groups.map((group: any, index: number) => (
          <div key={index} className={styles.group} style={groupStyle}>
            {group?.map((item: any, index: number) => (
              <InstagramPost
                key={index}
                caption={item.caption}
                mediaUrl={item.mediaUrl}
                permalink={item.permalink}
              />
            ))}
          </div>
        ))}
      </div>
      <div className={classNames(styles.button, styles.right)}>
        <ButtonRound
          onClick={() => onClick('right')}
          arrowDirection="right"
        />
      </div>
    </div>
  )
}

export default Instagram