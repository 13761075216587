import styles from '@/styles/Home.module.scss'
import { getHighlights, getRunningQueriesThunk } from '@/api/highlights'
import HighlightArticles from '@/components/article/HighlightArticles/HighlightArticles'
import FreeAssembly from '@/components/common/FreeAssembly/FreeAssembly'
import Hero from '@/components/common/Hero/Hero'
import Instagram from '@/components/common/Instagram/Instagram'
import CategorySelector from '@/components/search/CategorySelector/CategorySelector'
import BaseLayout from '@/layout/BaseLayout/BaseLayout'
import { wrapper } from '@/store/store'
import { Roboto } from '@next/font/google'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import React, { useState } from 'react'
import { useDeviceType, DeviceType } from '@/utils/hooks/useDeviceType'
import { getPageTitle } from '@/utils/helpers'
import { useRouter } from 'next/router'
import LicensePlate from '@/components/vehicle/LicensePlate/LicensePlate'
import Button from '@/components/buttons/Button/Button'

type Props = {}

const roboto = Roboto({
  subsets: ['latin'],
  weight: ['400'],
})

export default function Home() {
  const deviceType = useDeviceType()
  const [selectedCategory, setSelectedCategory] = useState<string>()
  const router = useRouter()

  const { t } = useTranslation('intro')

  // method to handle license plate search form
  const onSubmitLicensePlate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    router.push(`wheels?searchplate=true`)
  }

  return (
    <BaseLayout>
      <Head>
        <title>{getPageTitle('')}</title>
      </Head>
      <main>
        <Hero />
        <section className={classNames(styles.welcome, 'row')}>
          <div className="col-xs-12 col-md-6">
            <h2>{t('welcomePromo')}</h2>
            {/* <span >{t('welcomePromo')}</span> */}
            <div className={classNames(roboto.className, styles.promoText)}>
              <ul>
                <li>{t('salesPoint1')}</li>
                <li>{t('salesPoint2')}</li>
                <li>{t('salesPoint3')}</li>
                <li>{t('salesPoint4')}</li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <h3>{t('findYourCar')}</h3>
            <form className='flex align-center' onSubmit={onSubmitLicensePlate}>
              <LicensePlate
                animated={true}
              />
              <Button
                label={t('common:search.searchButton')}
                rounded={true}
                medium={true}
                arrow={true}
                type={'submit'}
              />
            </form>
            <div className={classNames(roboto.className, styles.promoText)}>
              Du vill ha det bästa för din bil, och det är exakt vad vi erbjuder. Med vår enkla&nbsp;&nbsp;sökfunktion, snabb
              och säker leverans, och en kundtjänst som alltid står till tjänst, garanterar vi en bekymmersfri
              shoppingupplevelse. Låt oss hjälpa dig hitta det du söker och ge din bil den status den förtjänar.
            </div>
          </div>
        </section>
        <CategorySelector />
        <HighlightArticles />
        <FreeAssembly />
        <Instagram />
      </main>
    </BaseLayout>
  )
}

// export const getServerSideProps = wrapper.getServerSideProps(
//   (store) => async () => {
//     store.dispatch(getHighlights.initiate())
//     await Promise.all(store.dispatch(getRunningQueriesThunk()))

//     return { props: {} }
//   },

// )

// export const getStaticProps: GetStaticProps<Props> = async ({
//   locale,
// }) => ({
//   props: {
//     ...(await serverSideTranslations(locale ?? 'en', [
//       'common',
//       'footer',
//       'intro',
//     ])),
//   },
// })

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async (context) => {
    // Fetch data for server-side rendering
    // store.dispatch(getFeed.initiate())

    // console.log(context.req.headers.cookie)

    store.dispatch(getHighlights.initiate())
    await Promise.all(store.dispatch(getRunningQueriesThunk()))

    // Fetch translations for static rendering
    const translations = await serverSideTranslations(context.locale || 'en', [
      'common',
      'footer',
      'intro',
      'shop',
    ])

    return {
      props: {
        ...translations,
      },
    }
  }
)