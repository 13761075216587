import LicensePlateInput from '@/components/inputs/LicensePlateInput/LicensePlateInput'
import { selectLicensePlate, selectLicensePlateCountryCode, setLicensePlate, setLicensePlateCountry, setLicensePlateValid } from '@/features/vehicle/vehicleSlice'
import { useAppDispatch } from '@/utils/hooks/useAppDispatch'
import { useAppSelector } from '@/utils/hooks/useAppSelector'
import { useCountry } from '@/utils/hooks/useCountry'
import React, { useEffect } from 'react'

type Props = {
  animated?: boolean
}

const LicensePlate = (props: Props) => {
  const licensePlate = useAppSelector(selectLicensePlate)
  const countryCodeState = useAppSelector(selectLicensePlateCountryCode)
  const dispatch = useAppDispatch()
  const { countryCode: siteCountryCode } = useCountry()

  const onValueChange = (value: string, isValid: boolean) => {
    dispatch(setLicensePlateValid(isValid))
    dispatch(setLicensePlate(value))
  }

  // update state country code if site country code changes, SE, DK, NO, FI, otherwise fallback to SE
  useEffect(() => {
    const allowedCountryCodes = ['AU', 'BR', 'DK', 'IE', 'FI', 'FR', 'DE', 'IT', 'NL', 'NZ', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB', 'US']

    if (siteCountryCode && allowedCountryCodes.includes(siteCountryCode))
      dispatch(setLicensePlateCountry(siteCountryCode))
    else
      dispatch(setLicensePlateCountry('SE'))
  }, [siteCountryCode, dispatch])

  return (
    <div>
      <LicensePlateInput
        value={licensePlate || ''}
        countryCode={countryCodeState}
        animated={props.animated}
        onValueChange={onValueChange}
      />
    </div>
  )
}

export default LicensePlate