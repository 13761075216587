import { useAddCartRowMutation, useAddWheelPackageMutation } from "@/api/cart"
import { CartAddWheelPackageModel, CartRowEditModel } from "@/types/api"
import { useRouter } from "next/router"
import useAnalyticsManager from "./useAnalyticsManager"

type AddToCartItem = {
  articleId: number
  quantity: number
  kType?: number
  licensePlate?: string
  // used to send to analytics
  price: number
  // used to send to analytics
  articleText: string
}

type AddWheelPackageToCartItem = {
  articleIds: number[]
  quantity: number
  kTypeId: number
  licensePlate?: string
  packageId?: number
}

const useAddToCart = () => {
  const [addArticleToCart] = useAddCartRowMutation()
  const [addWheelPackage] = useAddWheelPackageMutation()
  const router = useRouter()
  const analyticsManager = useAnalyticsManager()

  const addToCart = async (payload: AddToCartItem) => {
    try {
      const model = { articleId: payload.articleId, quantity: payload.quantity, kType: payload.kType, licensePlate: payload.licensePlate } as CartRowEditModel
      await addArticleToCart(model)
      router.push("/cart")
      analyticsManager.addToCart(payload.articleId, payload.articleText, payload.quantity, payload.price)
    } catch (error) {
      console.log(error)
    }
  }

  const addPackageToCart = async (payload: AddWheelPackageToCartItem) => {
    try {
      const model = { articleIds: payload.articleIds, quantity: payload.quantity, kType: payload.kTypeId, licensePlate: payload.licensePlate, packageId: payload.packageId } as CartAddWheelPackageModel
      await addWheelPackage(model)
      router.push("/cart")
      analyticsManager.addToCart(payload.articleIds[0], "", payload.quantity, 0)
      analyticsManager.addToCart(payload.articleIds[1], "", payload.quantity, 0)
    } catch (error) {
      console.log(error)
    }
  }

  return { addToCart, addPackageToCart }
}

export default useAddToCart