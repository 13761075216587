import styles from './Banner.module.scss'
import BannerIcon from '@/assets/svg/banner.svg'
import React from 'react'

type Props = {
  text: string
}

const Banner = (props: Props) => {
  return (
    <div className={styles.component}>
      <BannerIcon />
      <div>{props.text}</div>
    </div>
  )
}

export default Banner