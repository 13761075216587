import React from 'react'

type Props = {
  content: string,
  className?: string,
  tagName?: keyof JSX.IntrinsicElements,
}

const Raw = (props: Props) => {
  const Tag = props.tagName || 'div'

  return (
    <Tag
      className={props.className}
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  )
}

export default Raw